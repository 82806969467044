/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import {
  setRequestStateRequesting,
  setRequestStateFulfilled,
  setRequestStateFailed,
  setRequestStateCancelled,
} from "store/apiRequestStates/setRequestState";

const initialState = {
  data: {},
  error: {},
  requestStates: {},
  addingRequestStates: {},
};

const guestRequestsSlice = createSlice({
  name: "guestRequests",
  initialState,
  reducers: {
    fetchGuestRequests(state, { payload }) {
      const { reservationId } = payload;
      state.requestStates[reservationId] = setRequestStateRequesting(
        state.requestStates[reservationId]
      );
    },

    fetchGuestRequestsFulfilled(state, { payload }) {
      const { reservationId, guestRequests } = payload;
      state.data[reservationId] = guestRequests;
      delete state.error[reservationId];
      state.requestStates[reservationId] = setRequestStateFulfilled(
        state.requestStates[reservationId]
      );
    },

    fetchGuestRequestsFailed(state, { payload }) {
      const { reservationId, error } = payload;
      state.data[reservationId] = [];
      state.error[reservationId] = error;
      state.requestStates[reservationId] = setRequestStateFailed(
        state.requestStates[reservationId]
      );
    },

    fetchGuestRequestsCancel(state, { payload }) {
      const { reservationId } = payload;
      state.requestStates[reservationId] = setRequestStateCancelled(
        state.requestStates[reservationId]
      );
    },

    guestRequestsReset() {
      return initialState;
    },

    addRequestEvent(state, { payload }) {
      state.addingRequestStates[payload.requestId] = "SUBMITTING";
    },

    addRequestEventFulfilled(state, { payload }) {
      state.addingRequestStates[payload.requestId] = "COMPLETE";
    },

    addRequestEventFailed(state, { payload }) {
      state.addingRequestStates[payload.requestId] = "FAILED";
    },

    addRequestEventCancel(state, { payload }) {
      state.addingRequestStates[payload.requestId] = null;
    },
  },
});

export const {
  fetchGuestRequests,
  fetchGuestRequestsFulfilled,
  fetchGuestRequestsFailed,
  fetchGuestRequestsCancel,
  guestRequestsReset,

  addRequestEvent,
  addRequestEventFulfilled,
  addRequestEventFailed,
  addRequestEventCancel,
} = guestRequestsSlice.actions;

export default guestRequestsSlice.reducer;
