import React from "react";
import { useSelector } from "react-redux";

import { useTranslation } from "hooks";
import { BookingFlowLoadingIndicator } from "BookingFlow/components";
import ThemeContextProvider, { THEMES } from "contexts/ThemeContext";
import { selectProfile, selectMailSubscriptionList } from "store/profile";
import EmailSubscriptions from "../EditProfileView/components/EmailSubscriptions/EmailSubscriptions";

export default function EmailSubscriptionsView() {
  const { i18n } = useTranslation();

  // REQUIRED DATA FOR VIEW
  // profile
  const hasProfile = Boolean(useSelector(selectProfile));
  // mailSubscriptionList
  const hasMailSubscriptionList =
    Object.keys(useSelector(selectMailSubscriptionList)).length > 0;

  if (!hasProfile || !hasMailSubscriptionList) {
    return <BookingFlowLoadingIndicator opaque />;
  }

  return (
    <ThemeContextProvider theme={THEMES.DARK}>
      <div className={`layout--profile ${i18n.dir(i18n.language)}`}>
        <div className="layout--profile-fullscreen view--profile-edit profile-email-subscriptions">
          <div className="container">
            <EmailSubscriptions />
          </div>
        </div>
      </div>
    </ThemeContextProvider>
  );
}
